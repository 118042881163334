<template>
    <dashboard-layout>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col>
                        {{ fan.name }}
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.fans.index'}">return</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-card>
                            <v-card-text>
                                <v-form>
                                    <v-row>
                                        <v-col>
                                            <city-selector
                                                return-object
                                                v-model="fan.city"
                                                outlined
                                                hide-details="auto"
                                            />
                                        </v-col>

                                        <v-col lg="2">
                                            <v-text-field
                                                outlined
                                                v-model="fan.zip"
                                                label="Zip Code"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-btn color="success" @click="saveFan">
                                                Save
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </dashboard-layout>
</template>

<script>
import Fan from "@/models/Fan";
import CitySelector from "@/components/CitySelector";
import DashboardLayout from "@/layouts/DashboardLayout";

export default {
    name: "users-show",
    components: {DashboardLayout, CitySelector},
    data: function () {
        return {
            fan: {},
        }
    },
    async mounted() {
        const fanId = this.$route.params.id
        this.fan = await Fan.find(fanId)
    },
    methods: {
        saveFan() {
            this.fan.save()
        }
    },
    watch: {
        "fan.city": function (val) {
            this.fan.city_id = val.id
        }
    }
}
</script>

<style scoped>

</style>
